// Increament this number to bust the cache. Should be done just before a new deployment... Date.now()
app.constant("VERSION", "1744239908474");


app.constant("AUTH_EVENTS", {
	loginSuccess: "auth-login-success",
	loginFailed: "auth-login-failed",
	logoutSuccess: "auth-logout-success",
	sessionTimeout: "auth-session-timeout",
	notAuthenticated: "auth-not-authenticated",
	notAuthorized: "auth-not-authorized",
});

app.constant("USER_ROLES", {
	all: "*",
	admin: "admin",
	editor: "editor",
	guest: "guest",
});

// Used in the examination form to populate value in a select for both the left and right eyes
app.constant("LETTER", [
	{
		name: "+3",
		value: 3,
	},
	{
		name: "+2",
		value: 2,
	},
	{
		name: "+1",
		value: 1,
	},
	{
		name: "0",
		value: 0,
	},
	{
		name: "-1",
		value: -1,
	},
	{
		name: "-2",
		value: -2,
	},
	{
		name: "-3",
		value: -3,
	},
]);

// Used in the examination form to populate value in a select for both the left and right eyes
app.constant("LINE", [
	{
		name: "4.8",
		value: "_4_8",
	},
	{
		name: "6",
		value: "_6",
	},
	{
		name: "7.5",
		value: "_7_5",
	},
	{
		name: "9",
		value: "_9",
	},
	{
		name: "12",
		value: "_12",
	},
	{
		name: "15",
		value: "_15",
	},
	{
		name: "18",
		value: "_18",
	},
	{
		name: "21",
		value: "_21",
	},
	{
		name: "24",
		value: "_24",
	},
	{
		name: "30",
		value: "_30",
	},
	{
		name: "36",
		value: "_36",
	},
	{ 
		name: "42", 
		value: "_42",
	},
	{
		name: "48",
		value: "_48",
	},
	{
		name: "60",
		value: "_60",
	},
	{
		name: "120",
		value: "_120",
	},
	{
		name: "CF",
		value: "CF_30_CM",
	},
	{
		name: "HM",
		value: "HM_30_CM",
	},
	{
		name: "POL",
		value: "PL",
	},
	{
		name: "NPL",
		value: "NPL",
	},
]);

// Used in the examination form to populate value in a select for both the left and right eyes
app.constant("CYL", [
	{ name: "less than -10", value: "-100" },
	{ name: "-10", value: -10 },
	{ name: "-9.75", value: -9.75 },
	{ name: "-9.5", value: -9.5 },
	{ name: "-9.25", value: -9.25 },
	{ name: "-9.0", value: -9.0 },
	{ name: "-8.75", value: -8.75 },
	{ name: "-8.5", value: -8.5 },
	{ name: "-8.25", value: -8.25 },
	{ name: "-8.0", value: -8.0 },
	{ name: "-7.75", value: -7.75 },
	{ name: "-7.5", value: -7.5 },
	{ name: "-7.25", value: -7.25 },
	{ name: "-7.0", value: -7.0 },
	{ name: "-6.75", value: -6.75 },
	{ name: "-6.5", value: -6.5 },
	{ name: "-6.25", value: -6.25 },
	{ name: "-6.0", value: -6.0 },
	{ name: "-5.75", value: -5.75 },
	{ name: "-5.5", value: -5.5 },
	{ name: "-5.25", value: -5.25 },
	{ name: "-5.0", value: -5.0 },
	{ name: "-4.75", value: -4.75 },
	{ name: "-4.5", value: -4.5 },
	{ name: "-4.25", value: -4.25 },
	{ name: "-4.5", value: -4.5 },
	{ name: "-4.25", value: -4.25 },
	{ name: "-4.0", value: -4.0 },
	{ name: "-3.75", value: -3.75 },
	{ name: "-3.5", value: -3.5 },
	{ name: "-3.25", value: -3.25 },
	{ name: "-3.0", value: -3.0 },
	{ name: "-2.75", value: -2.75 },
	{ name: "-2.5", value: -2.5 },
	{ name: "-2.25", value: -2.25 },
	{ name: "-2.0", value: -2.0 },
	{ name: "-1.75", value: -1.75 },
	{ name: "-1.5", value: -1.5 },
	{ name: "-1.25", value: -1.25 },
	{ name: "-1.0", value: -1.0 },
	{ name: "-0.75", value: -0.75 },
	{ name: "-0.5", value: -0.5 },
	{ name: "-0.25", value: -0.25 },
	{ name: "0", value: 0 },
	{ name: "0.25", value: 0.25 },
	{ name: "0.5", value: 0.5 },
	{ name: "0.75", value: 0.75 },
	{ name: "1.0", value: 1.0 },
	{ name: "1.25", value: 1.25 },
	{ name: "1.5", value: 1.5 },
	{ name: "1.75", value: 1.75 },
	{ name: "2.0", value: 2.0 },
	{ name: "2.25", value: 2.25 },
	{ name: "2.5", value: 2.5 },
	{ name: "2.75", value: 2.75 },
	{ name: "3.0", value: 3.0 },
	{ name: "3.25", value: 3.25 },
	{ name: "3.5", value: 3.5 },
	{ name: "3.75", value: 3.75 },
	{ name: "4.0", value: 4.0 },
	{ name: "4.25", value: 4.25 },
	{ name: "4.5", value: 4.5 },
	{ name: "4.75", value: 4.75 },
	{ name: "5.0", value: 5.0 },
	{ name: "5.25", value: 5.25 },
	{ name: "5.5", value: 5.5 },
	{ name: "5.75", value: 5.75 },
	{ name: "6.0", value: 6.0 },
	{ name: "6.25", value: 6.25 },
	{ name: "6.5", value: 6.5 },
	{ name: "6.75", value: 6.75 },
	{ name: "7.0", value: 7.0 },
	{ name: "7.25", value: 7.25 },
	{ name: "7.5", value: 7.5 },
	{ name: "7.75", value: 7.75 },
	{ name: "8.0", value: 8.0 },
	{ name: "8.25", value: 8.25 },
	{ name: "8.5", value: 8.5 },
	{ name: "8.75", value: 8.75 },
	{ name: "9.0", value: 9.0 },
	{ name: "9.25", value: 9.25 },
	{ name: "9.5", value: 9.5 },
	{ name: "9.75", value: 9.75 },
	{ name: "10.0", value: 10.0 },
	{ name: "More than 10+", value: "100" },
]);

app.constant("DEVICE", [
	{ name: "iCare", value: "ICARE" },
	{ name: "Goldman", value: "GOLDMAN" },
	{ name: "Tonopen", value: "TONOPEN" },
	{ name: "Tonoref", value: "TONOREF" },
	{ name: "other", value: "OTHER" },
]);

app.constant("LENS_STATUS", [
	{ name: "Phakic", value: "PHAKIC" },
	{ name: "Pseudophakic", value: "PSEUDO_PHAKIC" },
	{ name: "Pseudophakic with PCO", value: "PSEUDO_PHAKIC_WITH_PCO" },
	{ name: "Aphakic", value: "APHAKIC" },
]);

app.constant("NUCLEAR_OPALESCENCE", [
	{ name: "Absent", value: "ABSENT" },
	{ name: "NO1", value: "NO1" },
	{ name: "NO2", value: "NO2" },
	{ name: "NO3", value: "NO3" },
	{ name: "NO4", value: "NO4" },
	{ name: "NO5", value: "NO5" },
	{ name: "NO6", value: "NO6" },
]);

app.constant("NUCLEAR_COLOUR", [
	{ name: "Absent", value: "ABSENT" },
	{ name: "NC1", value: "NC1" },
	{ name: "NC2", value: "NC2" },
	{ name: "NC3", value: "NC3" },
	{ name: "NC4", value: "NC4" },
	{ name: "NC5", value: "NC5" },
	{ name: "NC6", value: "NC6" },
]);

app.constant("CORTICAL", [
	{ name: "Absent", value: "ABSENT" },
	{ name: "C1", value: "C1" },
	{ name: "C2", value: "C2" },
	{ name: "C3", value: "C3" },
	{ name: "C4", value: "C4" },
	{ name: "C5", value: "C5" },
]);

app.constant("POST_SUBCAPSULAR", [
	{ name: "Absent", value: "ABSENT" },
	{ name: "P1", value: "P1" },
	{ name: "P2", value: "P2" },
	{ name: "P3", value: "P3" },
	{ name: "P4", value: "P4" },
	{ name: "P5", value: "P5" },
]);

app.constant("PUPIL_SIZE", [
	{ name: "2mm", value: "_2mm" },
	{ name: "3mm", value: "_3mm" },
	{ name: "4mm", value: "_4mm" },
	{ name: "5mm", value: "_5mm" },
	{ name: "6mm", value: "_6mm" },
	{ name: "7mm", value: "_7mm" },
	{ name: "8mm", value: "_8mm" },
	{ name: "9mm", value: "_9mm" },
]);

app.constant("MACULOPATHY", [
	{ name: "M0", value: "M0" },
	{ name: "M1", value: "M1" },
	{ name: "M2", value: "M2" },
	{ name: "M3", value: "M3" },
	{ name: "M4", value: "M4" },
	{ name: "M5", value: "M5" },
	{ name: "MT", value: "MT" },
	{ name: "No view", value: "NO_VIEW" },
]);

app.constant("RETINOPATHY", [
	{ name: "R0", value: "R0" },
	{ name: "R1", value: "R1" },
	{ name: "R2", value: "R2" },
	{ name: "R3", value: "R3" },
	{ name: "R4", value: "R4" },
	{ name: "R5", value: "R5" },
	{ name: "RT", value: "RT" },
	{ name: "No view", value: "NO_VIEW" },
]);

app.constant("LENS_TYPE", [
	{ name: "Monofocal", value: "NON_TORIC" },
	{ name: "Monofocal toric", value: "TORIC" },
	{ name: "Refractive EDoF", value: "EDOF" },
	{ name: "Refractive EDoF toric", value: "TORIC_EDOF" },
	{ name: "Diffractive/multifocal", value: "MULTIFOCAL" },
	{ name: "Diffractive/multifocal toric", value: "TORIC_MULTIFOCAL" },
	{ name: "Addon/phakic IOL/ICL", value: "NON_TORIC_ADDON" },
	{ name: "Addon/phakic IOL/ICL toric", value: "TORIC_ADDON" },
	{ name: "Other", value: "OTHER" },
]);

app.constant("LENSMODEL_MONOFOCAL", [
	{ name: "Alcon SN60WF/AU00T0", value: "SN60WF/AU00T0" },
	{ name: "Alcon Clareon", value: "Clareon" },
	{ name: "Alcon SA60AT", value: "SA60AT"},
	{ name: "Alcon MA60AC", value: "MA60AC"},
	{ name: "Alcon MA60MA", value: "MA60MA" },
	{ name: "Akreos Adapt AO", value: "Adapt AO"},
	{ name: "Aurolab Aurovue", value: "Aurolab Aurovue"},
	{ name: "B&L enVista", value: "enVista" },
	{ name: "RayOne Aspheric", value: "RayOne Aspheric" },
	{ name: "Tecnis Eyhance", value: "Tecnis Eyhance" },
	{ name: "Tecnis Monofocal", value: "Tecnis Monofocal" },
	{ name: "Tecnis Sensar", value: "Tecnis Sensar" },
]);

app.constant("LENSMODEL_MONOFOCAL_TORIC", [
	{ name: "Alcon Clareon", value: "Clareon" },
	{ name: "Alcon SN6ATx", value: "SN6ATx" },
	{ name: "B&L enVista", value: "enVista" },
	{ name: "RayOne Aspheric", value: "RayOne Aspheric" },
	{ name: "Tecnis Eyhance", value: "Tecnis Eyhance" },
	{ name: "Tecnis Monofocal", value: "Tecnis Monofocal" },
	{ name: "Teleon Custom", value: "Teleon Custom" },
	{ name: "Galaxy", value: "Galaxy" },
]);

app.constant("LENSMODEL_EDOF", [
	{ name: "Acufocus IC-8", value: "IC-8" },
	{ name: "Alcon Vivity", value: "Vivity" },
	// { name: "Lentis Comfort", value: "Comfort" },
	{ name: "RayOne EMV", value: "RayOne EMV" },
	{ name: "Tecnis PureSee", value: "Tecnis PureSee"},
	{ name: "Teleon Comfort", value: "Teleon Comfort"},
	{ name: "Teleon MF30", value: "Teleon MF30"},
	{ name: "Teleon Vario", value: "Teleon Vario"},
	{ name: "Teleon VarioMax", value: "Teleon VarioMax"},
	// { name: "SIFI Mini Well", value: "Mini Well" },
	// { name: "Tecnis Eyhance", value: "Eyhance" },
]);

app.constant("LENSMODEL_EDOF_TORIC", [
	{ name: "Alcon Vivity", value: "Vivity" },
	{ name: "RayOne EMV", value: "RayOne EMV" },
	{ name: "Tecnis PureSee", value: "Tecnis PureSee"},
	{ name: "Teleon Comfort", value: "Teleon Comfort"},
	{ name: "Teleon MF30", value: "Teleon MF30"},
	{ name: "Teleon Vario", value: "Teleon Vario"},
	{ name: "Teleon VarioMax", value: "Teleon VarioMax"},
	// { name: "Tecnis Eyhance", value: "Eyhance" },
	// { name: "Lentis Comfort", value: "Comfort" },
	// { name: "SIFI Mini Well", value: "Mini Well" },
]);

app.constant("LENSMODEL_MULTIFOCAL", [
	{ name: "Alcon PanOptix", value: "PanOptix" },
	{ name: "B&L FineVision", value: "FineVision" },
	{ name: "Tecnis Symfony", value: "Symfony" },
	{ name: "Tecnis Synergy", value: "Synergy" },
	{ name: "ClearView 3", value: "ClearView 3" },
	{ name: "Galaxy", value: "Galaxy" },
]);

app.constant("LENSMODEL_MULTIFOCAL_TORIC", [
	{ name: "Alcon PanOptix", value: "PanOptix" },
	{ name: "B&L FineVision", value: "FineVision" },
	{ name: "Tecnis Symfony", value: "Symfony" },
	{ name: "Tecnis Synergy", value: "Synergy" },
	{ name: "Galaxy", value: "Galaxy" },
]);


app.constant("LENSMODEL_ADDON", [
	{ name: "Medicontur 1stQ", value: "1stQ" },
	{ name: "Rayner Sulcoflex", value: "Sulcoflex" },
	{ name: "Visian ICL", value: "Visian ICL" },
	{ name: "Viva ICL", value: "Viva ICL" },
]);

app.constant("LENSMODEL_ADDON_TORIC", [
	{ name: "Medicontur 1stQ", value: "1stQ" },
	{ name: "Rayner Sulcoflex", value: "Sulcoflex" },
	{ name: "Visian ICL", value: "Visian ICL" },
	{ name: "Viva ICL", value: "Viva ICL" },
]);



app.constant("LENSMODEL", [
	{ name: "Alcon SN60WF/AU00T0", value: "SN60WF/AU00T0" },
	{ name: "Alcon SA60WF", value: "SA60WF" },
	{ name: "Alcon MA60MA", value: "MA60MA" },
	{ name: "Alcon SN6ATx", value: "SN6ATx" },
	{ name: "Alcon Clareon", value: "Clareon" },
	{ name: "Alcon MA60AC", value: "MA60AC"},
	{ name: "Alcon SA60AT", value: "SA60AT"},
	{ name: "Alcon Vivity", value: "Vivity" },
	{ name: "Alcon PanOptix", value: "PanOptix" },
	{ name: "Akreos Adapt AO", value: "Adapt AO"},
	{ name: "Acufocus IC-8", value: "IC-8" },
	{ name: "B&L enVista", value: "enVista" },
	{ name: "B&L FineVision", value: "FineVision" },
	{ name: "Lentis Comfort", value: "Comfort" },
	{ name: "Medicontur 1stQ", value: "1stQ" },
	{ name: "Rayner Sulcoflex", value: "Sulcoflex" },
	{ name: "SIFI Mini Well", value: "Mini Well" },
	{ name: "Teleon Comfort", value: "Teleon Comfort"},
	{ name: "Tecnis Monofocal", value: "Tecnis Monofocal" },
	{ name: "Tecnis Eyhance", value: "Eyhance" },
	{ name: "Tecnis PureSee", value: "Tecnis PureSee"},
	{ name: "Tecnis Symfony", value: "Symfony" },
	{ name: "Tecnis Synergy", value: "Synergy" },
	{ name: "Teleon Custom", value: "Teleon Custom" },
	{ name: "Teleon MF30", value: "Teleon MF30"},
	{ name: "Teleon Vario", value: "Teleon Vario"},
	{ name: "Teleon VarioMax", value: "Teleon VarioMax"},
	
]);

app.constant("REACTIONS", [
	{ name: "Rash", value: "RASH" },
	{ name: "GI upset", value: "GI_UPSET" },
	{ name: "Anaphylaxis", value: "ANAPHYLAXIS" },
]);

app.constant("ANAESTHETIC", [
	{ name: "Topical/subtenons", value: "TOPICAL_SUBTENONS" },
	{ name: "Local with sedation", value: "LOCAL_SEDATION" },
	{ name: "General", value: "GENERAL" },
]);

app.constant("REASON", [
	{ name: "Anxiety", value: "ANXIETY" },
	{ name: "Tremor/positioning", value: "TREMOR_POSITIONING" },
	{ name: "Patient preference", value: "PATIENT_PREFERENCE" },
	{ name: "Cognitive impairment", value: "COGNITIVE_IMPAIRMENT" },
	{ name: "Other", value: "OTHER" },
]);

app.constant("VISUALACUITYLINE", [
	{ name: "4.8", value: "_4_8"},
	{ name: "6", value: "_6" },
	{ name: "7.5", value: "_7_5" },
	{ name: "9", value: "_9" },
	{ name: "12", value: "_12" },
	{ name: "15", value: "_15" },
	{ name: "18", value: "_18" },
	{ name: "21", value: "_21" },
	{ name: "24", value: "_24" },
	{ name: "30", value: "_30" },
	{ name: "36", value: "_36" },
	{ name: "42", value: "_42" },
	{ name: "48", value: "_48" },
	{ name: "60", value: "_60" },
	{ name: "120", value: "_120" },
	{ name: "CF", value: "CF_30_CM" },
	{ name: "HM", value: "HM_30_CM" },
	{ name: "PL", value: "PL" },
	{ name: "NPL", value: "NPL" },
]);

app.constant("OPERATIONPROCEDURE", [
	{ name: "Phaco and IOL", id: "PHACO_IOL" },
	{ name: "Phaco", id: "PHACO_NO_IOL" },
	{ name: "Addon/phakic IOL/ICL implantation", id: "IOL_ADDON" },
	{ name: "IOL implantation", id: "IOL_IMPLANTATION" },
	{ name: "IOL exchange", id: "IOL_EXCHANGE" },
	{ name: "Toric IOL axis realignment", id: "TORIC_IOL_AXIS_REALIGNMENT" },
	{ name: "Other", id: "OTHER"},
]);

app.constant("OPERATIONANAESTHETIC", [
	{ name: "Topical", id: "TOPICAL" },
	{ name: "Topical with intracameral", id: "TOPICAL_INTRACAMERAL" },
	{ name: "Sub-Tenon’s", id: "SUBTENONS" },
	{ name: "Peribulbar", id: "PERIBULBAR" },
	// { name: "topical sub tenons", id: "TOPICAL_SUBTENONS" },
	{ name: "Topical/subtenons", id: "TOPICAL_SUBTENONS" },
	{ name: "Local", id: "LOCAL" },
	{ name: "Local with sedation", id: "LOCAL_SEDATION" },
	{ name: "General", id: "GENERAL" },
]);

app.constant("INCISIONSIZE", [
	{ name: "1.1", id: "1.1" },
	{ name: "1.8", id: "1.8" },
	{ name: "2.0", id: "2.0" },
	{ name: "2.2", id: "2.2" },
	{ name: "2.4", id: "2.4" },
	{ name: "2.6", id: "2.6" },
	{ name: "2.8", id: "2.8" },
	{ name: "3.0", id: "3.0" },
	{ name: "3.2", id: "3.2" },
	{ name: "3.4", id: "3.4" },
]);

app.constant("OVD", [
	{ name: "Healon", value: "Healon" },
	{ name: "Healon 5", value: "Healon 5"},
	{ name: "Healon GV Pro", value: "Healon GV Pro" },
	{ name: "Provisc", value: "Provisc"},
	{ name: "Viscoat", value: "Viscoat" },
	{ name: "Duovisc", value: "Duovisc" },
	{ name: "Aurogel", value: "Aurogel" },
	{ name: "Discovisc", value: "Discovisc" },
	{ name: "Other", value: "Other" },
]);

// app.constant("PUPILEXPANDER", [
// 	{ name: "None", value: "NONE" },
// 	{ name: "Malyugan ring", value: "MALYUGAN_RING" },
// 	{ name: "Iris hooks", value: "IRIS_HOOKS" },
// 	{ name: "other", value: "OTHER" },
// ]);

app.constant("PUPILEXPANDER", [
	{ name: "None", id: "NONE" },
	{ name: "Malyugan ring", id: "Malyugan ring" },
	{ name: "Iris hooks", id: "Iris hooks" },
]);

app.constant("CCCTECHNIQUE", [
	{ name: "Cystotome + Rhexis forceps", id: "Cystotome + Rhexis forceps" },
	{ name: "Rhexis forceps", id: "Rhexis forceps" },
	{ name: "Cystotome", id: "Cystotome" },
]);

app.constant("HYDRODISSECTION", [
	{ name: "BSS", value: "BSS" },
	{ name: "Anaesthetic", value: "ANAESTHETIC" },
	{ name: "OVD", value: "OVD" },
	{ name: "None", value: "NONE" },
]);

app.constant("PHACOMACHINE", [
	{ name: "Infinity", value: "Infinity" },
	{ name: "Constellation", value: "Constellation" },
	{ name: "Centurion", value: "Centurion" },
	{ name: "Stellaris", value: "Stellaris" },
	{ name: "Whitestar Signature", value: "Whitestar Signature" },
	{ name: "Veritas", value: "Veritas"},
	{ name: "Other", value: "Other" },
]);

app.constant("PHACOTECHNIQUE", [
	{ name: "Divide and conquer", id: "Divide and conquer" },
	{ name: "Stop and chop", id: "Stop and chop" },
	{ name: "Horizontal chop", id: "Horizontal chop" },
	{ name: "Vertical chop", id: "Vertical chop" },
	{ name: "Bimanual", id: "Bimanual" },
]);

app.constant("PHACOIRRIGATION", [
	{ name: "Bimanual", id: "Bimanual" },
	{ name: "Unipak", id: "Unipak" },
	{ name: "Coaxial", id: "Coaxial" },
	{ name: "90 deg coaxial", id: "90 deg coaxial" },
]);

app.constant("LENSPLACEMENT", [
	{ name: "In the bag", id: "In the bag" },
	{ name: "Sulcus", id: "Sulcus" },
	{ name: "Optic capture", id: "Optic capture" },
	{ name: "Reverse optic capture", id: "Reverse optic capture" },
	{ name: "Iris fixated", id: "Iris fixated" },
	{ name: "Scleral fixated", id: "Scleral fixated" },
	{ name: "Anterior chamber", id: "Anterior chamber"}
]);

app.constant("INTRAOPERATIVEMEDICATIONS", [
	{ name: 'Intracameral cefazolin', id: 'Intracameral cefazolin'},
	{ name: "Intracameral cefuroxime", id: "Intracameral cefuroxime" },
	{ name: "Intracameral miochol", id: "Intracameral miochol" },
	{ name: "Intracameral miostat", id: "Intracameral miostat" },
	{ name: "Intracameral moxifloxacin", id: "Intracameral moxifloxacin" },
	{ name: "Subconjunctival cefazolin", id: "Subconjunctival cefazolin" },
	{ name: "Subconjunctival gentamycin", id: "Subconjunctival gentamycin"},
	{ name: "Subconjunctival dexamethasone", id: "Subconjunctival dexamethasone" },

	{ name: "Topical oc. chloramphenicol", id: "Topical oc. chloramphenicol" },
	{ name: "Topical g. chloramphenicol", id: "Topical g. chloramphenicol" },
	{ name: "Topical oc. maxitrol", id: "Topical oc. maxitrol" },
	{ name: "Topical oc. maxidex", id: "Topical oc. maxidex" },
	{ name: "Topical g. prednisolone", id: "Topical g. prednisolone" },
	{ name: "Subconjunctival triamcinolone 2mg", id: "Subconjunctival triamcinolone 2mg"},
	{ name: "Subconjunctival triamcinolone 4mg", id: "Subconjunctival triamcinolone 4mg"},
	{ name: "Subconjunctival triamcinolone 10mg", id: "Subconjunctival triamcinolone 10mg"}
]);

app.constant("MEDICATIONS", [
	"g. Chloramphenicol 0.5%",
	"g. Chloramphenicol 0.5% minims",
	"g. Prednisolone 1%",
	"g. Prednisolone 0.5% minims",
	"g. Maxidex 0.1%",
	"g. Maxitrol",
	"g. Voltaren",
	"g. Acular 0.5%",
	"oc. Chloramphenicol 1%",
	"oc. Maxidex 0.1%",
	"oc. Maxitrol",
	"g. Ciprofloxacin 0.3%",
	"g. Polytears",
	"Acetazolamide",
	"Paracetamol",
	"Diclofenac SR",
	"Codeine"
]);

app.constant("POSTOPERATIVEMAP", [
	{
		med: "g. Chloramphenicol 0.5%",
		dose: "1 drop",
		frequency: 4,
		route: null,
		duration: 14,
	},
	{
		med: "g. Chloramphenicol 0.5% minims",
		dose: "1 drop",
		frequency: 4,
		route: null,
		duration: 14,
	},
	{
		med: "g. Prednisolone 1%",
		dose: "1 drop",
		frequency: 4,
		route: null,
		duration: 28,
	},
	{
		med: "g. Prednisolone 0.5% minims",
		dose: "1 drop",
		frequency: 4,
		route: null,
		duration: 28,
	},
	{
		med: "g. Maxidex 0.1%",
		dose: "1 drop",
		frequency: 4,
		route: null,
		duration: 28,
	},
	{
		med: "g. Maxitrol",
		dose: "1 drop",
		frequency: 4,
		route: null,
		duration: 28,
	}, 
	{
		med: "g. Voltaren",
		dose: "1 drop",
		frequency: 4,
		route: null,
		duration: 28,
	},
	{
		med: "g. Acular 0.5%",
		dose: "1 drop",
		frequency: 4,
		route: null,
		duration: 28,
	},
	{
		med: "oc. Chloramphenicol 1%",
		dose: "apply",
		frequency: 4,
		route: null,
		duration: 14,
	},
	{
		med: "oc. Maxidex 0.1%",
		dose: "apply",
		frequency: 4,
		route: null,
		duration: 28,
	},
	{
		med: "oc. Maxitrol",
		dose: "apply",
		frequency: 4,
		route: null,
		duration: 28,
	},
	{
		med: "g. Ciprofloxacin 0.3%",
		dose: "1 drop",
		frequency: 4,
		route: null,
		duration: 14,
	},
	{
		med: "g. Polytears",
		dose: "1 drop",
		frequency: 4,
		route: null,
		duration: 28,
	},
	{
		med: "Acetazolamide",
		dose: "250mg",
		frequency: 3,
		route: "ORAL",
		duration: 1,
	},
	{
		med: "Paracetamol",
		dose: "1gm",
		frequency: 4,
		route: "ORAL",
		duration: 36,
	},
	{
		med: "Diclofenac SR",
		dose: "75mg",
		frequency: 2,
		route: "ORAL",
		duration: 7,
	},
	{
		med: "Codeine",
		dose: "30-60mg",
		frequency: 3,
		route: "ORAL",
		duration: 7,
	},
]);

app.constant("DOSE", [
	{ name: "DOSE 1", value: "dose_1" },
	{ name: "DOSE 2", value: "dose_2" },
	{ name: "DOSE 3", value: "dose_3" },
]);

app.constant("ROUTE", [
	{ name: "Right Eye", value: "OD" },
	{ name: "Left Eye", value: "OS" },
	{ name: "Both Eyes", value: "BOTH" },
	{ name: "Oral", value: "ORAL" },
]);

app.constant("VISUAL_POTENTIAL", [
	{ value: "_BETTER_THAN_6By12", name: "Better than 6/12" },
	{ value: "_BETTER_THAN_6By12_TO_6By36", name: "6/12 to 6/36" },
	{ value: "_WORSE_6By36", name: "Worse than 6/36" },
])

app.constant("LIFE_QUALITY_QUESTIONS",[
	{ model:"qol_C_1", name: "Reading text in newspapers"},
	{ model:"qol_C_2", name: "Recognising the faces of people you meet"},
	{ model:"qol_C_3", name: "Seeing the prices of goods when shopping"},
	{ model:"qol_C_4", name: "Seeing to walk on uneven surfaces, e.g. cobblestones"},
	{ model:"qol_C_5", name: "Seeing to do handicrafts woodwork etc"},
	{ model:"qol_C_6", name: "Reading subtitles on TV"},
	{ model:"qol_C_7", name: "Seeing to engage in an activity/hobby that you are interested in"},
])

app.constant("LIFE_QUALITY_QUESTIONS_REFERRAL",[
	{ model:"q3_sight_reading_text", name: "Reading text in newspapers"},
	{ model:"q4_sight_face_recognition", name: "Recognising the faces of people you meet"},
	{ model:"q5_sight_seeing_prices", name: "Seeing the prices of goods when shopping"},
	{ model:"q6_sight_walking", name: "Seeing to walk on uneven surfaces, e.g. cobblestones"},
	{ model:"q7_sight_handcraft", name: "Seeing to do handicrafts woodwork etc"},
	{ model:"q8_sight_reading_subtitles", name: "Reading subtitles on TV"},
	{ model:"q9_sight_engage_activities", name: "Seeing to engage in an activity/hobby that you are interested in"},
])

app.constant("MEDICAL_INSURANCE",[
	{ value: "AA", name: "AA" },
	{ value: "ACC", name: "ACC" },
	{ value: "ACCURO", name: "Accuro" },
	{ value: "AIA", name: "AIA" },
	{ value: "DHB_OUTSOURCED", name: "Public hospital outsourced" },
	{ value: "NIB", name: "NIB" },
	{ value: "NO_INSURANCE", name: "No insurance" },
	{ value: "OTHER", name: "Other" },
	{ value: "PARTNERS_LIFE", name: "Partners life" },
	{ value: "SOUTHERN_CROSS", name: "Southern Cross" },
	{ value: "UNIMED", name: "UniMed" },
])

app.constant("FEATURES",{
	PREMIUMLENS: true,
})

app.constant("USER_TYPE",[
	{ value: "OPTOMETRIST", name: "Optometrist" },
	{ value: "GP", name: "GP"},
	{ value: "OPHTHALMOGIST", name: "Ophthalmogist" },
	{ value: "REGISTERED_NURSE", name: "Registered Nurse"}, 
	{ value: "CLINICAL_NURSE_SPECIALIST", name: "Clinical Nurse Specialist"},
	{ value: "REGISTRAR", name: "Registrar"}
])

app.constant("USER_TITLE",[
	{ value: "DR", name: "Dr"},
	{ value: "PROF", name: "Prof"},
	{ value: "MR", name: "Mr" },
	{ value: "MRS", name: "Mrs" },
	{ value: "MS", name: "Ms" },
])

app.constant("USER_ROLE",[
	{ id: "ADMIN", label: "Admin"},
	{ id: "GP", label: "GP"},
	{ id: "OPTOMETRIST", label: "Optometrist"}, 
	{ id: "TECHNICIAN", label: "Technician"},
	{ id: "REGISTERED_NURSE", label: "Registered Nurse"},
	{ id: "CLINICAL_NURSE_SPECIALIST", label: "Clinical Nurse Specialist"}, 
	{ id: "REGISTRAR", label: "Registrar"},
	{ id: "OPHTHALMOLOGIST", label: "Ophthalmologist"},
	{ id: "PRACTICE_ADMIN", label: "Practice Admin" },
	// { id: "REFERRER", label: "Referrer (removed)" },
])

app.constant("PRACTICE_TYPE",[
	{value: "OPTOMETRY", name: "Optometry"},
	{value: "PRIVATE", name: "Private[ophthalmologist]"},
	{value: "DHB_PUBLIC", name: "Public hospital"},
	{value: "GP", name: "GP"},
	{value: "THEATRE", name: "Theatre"},
])

app.constant("DHB_LIST",[
	{value: "AUCKLAND", name: "AUCKLAND"},
	{value: "BAY_OF_PLENTY", name: "BAY_OF_PLENTY"},
	{value: "CANTERBURY", name: "CANTERBURY"},
	{value: "CAPITAL_AND_COST", name: "CAPITAL_AND_COST"},
	{value: "COUNTIES_MANUKAU", name: "COUNTIES_MANUKAU"},
	{value: "HAWKES_BAY", name: "HAWKES_BAY"},
	{value: "HUTT_VALLEY", name: "HUTT_VALLEY"},
	{value: "LAKES", name: "LAKES"},
	{value: "MID_CENTRAL", name: "MID_CENTRAL"},
	{value: "NELSON_MARLBOROUGH", name: "NELSON_MARLBOROUGH"},
	{value: "NORTHLAND", name: "NORTHLAND"},
	{value: "SOUTH_CANTERBURY", name: "SOUTH_CANTERBURY"},
	{value: "SOUTHERN", name: "SOUTHERN"},
	{value: "TAIRAWHITI", name: "TAIRAWHITI"},
	{value: "TARANAKI", name: "TARANAKI"},
	{value: "WAIKATO", name: "WAIKATO"},
	{value: "WAIRARAPA", name: "WAIRARAPA"},
	{value: "WAITEMATA", name: "WAITEMATA"},
	{value: "WEST_COST", name: "WEST_COST"},
	{value: "WHANGANUI", name: "WHANGANUI"},
])

app.constant("NHI_SERVICE_ERROR",
	{
		title: "The MoH NHI service has failed to return a result",
		body: "We apologise for the inconvenience. Please enter the details manually or try again later."
	}
)

app.constant("CONFIG", {
	NHI_ADDRESS: true
});

app.constant("POPULATE_DATA_SUCCESS", "The most recent clinical examination findings on record have been preloaded - please update any values that have changed");

app.constant("INJECTIONPROCEDURE", [
	{ name: "Intravitreal injection", id: "INTRAVITREAL_INJECTION" },
	{ name: "Intravitreal implant", id: "INTRAVITREAL_IMPLANT" },
	{ name: "Subtenons injection", id: "SUBTENONS_INJECTION" },
	{ name: "Orbital floor injection", id: "ORBITAL_FLOOR_INJECTION" },
	{ name: "Subconjunctival injection", id: "SUBCONJUNCTIVAL_INJECTION" },
	{ name: "Intravitreal tap and inject", id: "INTRAVITREAL_TAP_AND_INJECT" },
]);

app.constant("INDICATIONTYPE", [
	{ name: "Neovascular Age-related Macular Degeneration", id: "NEOVASCULAR_AMD" },
	{ name: "Retinal Angiomatous Proliferation (RAP)", id: "RAP" },
	{ name: "Polypoidal Choroidal Vasculopathy (PCV)", id: "PCV" },
	{ name: "Central retinal vein occlusion (CRVO)", id: "CENTRAL_RETINAL_VO" },
	{ name: "Branch retinal vein occlusion (BRVO)", id: "BRANCH_RETINAL_VO" },
	{ name: "Hemi retinal vein occlusion", id: "HEMI_RETINAL_VO"},
	{ name: "Diabetic macular oedema (DMO)", id: "DMO"},
	{ name: "Diabetic retinopathy (DR)", id: "DR"},
	{ name: "Diabetic macular oedema AND retinopathy", id: "DMO_AND_DR"},
	{ name: "Axial Myopia", id: "AXIAL_MYOPIA"},
	{ name: "Uveitis", id: "UVEITIS"},
	{ name: "Retinopathy of Prematurity (ROP)", id: "ROP"},
	{ name: "Cystoid Macular Oedema (CMO)", id: "CMO"},
	{ name: "Endophthalmitis", id: "ENDOPHTHALMITIS"},
	{ name: "Thyroid Eye Disease", id: "THYROID"},
	{ name: "Lymphoproliferative disease", id: "LYMPHOPROLIFERATIVE"},
	{ name: "Corneal neovascularisation", id: "CORNEAL_NEO"},
	{ name: "Other", id: "OTHER" },
]);

app.constant("OCULARTYPE", [
	{ name: "Early AMD", id: "EARLY_AMD" },
	{ name: "Geographic Atrophy", id: "GEOGRAPHIC_ATROPHY" },
	{ name: "nAMD", id: "NAMD" },
	{ name: "Diabetic retinopathy", id: "DIABETIC_RETINOPATHY" },
	{ name: "Axial myopia", id: "AXIAL_MYOPIA" },
	{ name: "Clinically significant media opacity", id: "CLINICALLY_SIGNIFICANT_OPACITY" },
	{ name: "Pseudophakia", id: "PSEUDOPHAKIA" },
	{ name: "Optic neuropathy including glaucoma", id: "OPTIC_NEUROPATHY_GLAUCOMA" },
	{ name: "Posterior uveitis", id: "POSTERIOR_UVEITIS" },
	{ name: "Vitreomacular traction/premacular fibrosis", id: "VITREOMACULAR_TRACTION_PREMACULAR" },
	{ name: "Amblyopia", id: "AMBLYOPIA" },
	{ name: "Retinal vascular disease", id: "RETINAL_VASCULAR_DISEASE" },
	{ name: "Yag Capsulotomy", id: "YAG_CAPSULOTOMY" },
	{ name: "Other macular pathology", id: "OTHER_MACULAR_PATHOLOGY" },
	{ name: "Vitrectomy", id: "VITRECTOMY" },
]);

app.constant("INJECTIONANAESTHETIC", [
	{ name: "Topical", id: "TOPICAL" },
	{ name: "Subconjunctival", id: "SUBCONJUNCTIVAL" },
	{ name: "Sub-Tenon’s", id: "SUBTENONS" },
	{ name: "Peribulbar", id: "PERIBULBAR" },
	{ name: "General", id: "GENERAL" },
]);

app.constant("INJECTIONPREPARATION", [
	{ name: "Povidone-Iodine", id: "POVIDONE_IODINE" },
	{ name: "Chlorhexidine", id: "CHLORHEXIDINE" },
	{ name: "None", id: "NONE" },
]);

app.constant("INJECTIONLOCATION", [
	{ name: "Superior", id: "SUPERIOR" },
	{ name: "Superior-temporal", id: "SUPERIOR_TEMPORAL" },
	{ name: "Temporal", id: "TEMPORAL" },
	{ name: "Inferior-temporal", id: "INFERIOR_TEMPORAL" },
	{ name: "Inferior", id: "INFERIOR" },
	{ name: "Inferior-nasal", id: "INFERIOR_NASAL" },
	{ name: "Nasal", id: "NASAL" },
	{ name: "Superior-nasal", id: "SUPERIOR_NASAL" },
]);

app.constant("DISTANCELIMBUS", [
	{ name: "1.0", id: "_1mm" },
	{ name: "1.5", id: "_1_5mm" },
	{ name: "2.0", id: "_2mm" },
	{ name: "2.5", id: "_2_5mm" },
	{ name: "3.0", id: "_3mm" },
	{ name: "3.5", id: "_3_5mm" },
	{ name: "4.0", id: "_4mm" },
	{ name: "4.5", id: "_4_5mm" },
	{ name: "5.0", id: "_5mm" },
]);

app.constant("INTRAVITREAL_MEDICATION", [
	{ name: "Bevacizumab (Avastin)", id: "BEVACIZUMAB" },
	{ name: "Aflibercept (Eylea)", id: "AFLIBERCEPT" },
	{ name: "Ranabizumab (Lucentis)", id: "RANABIZUMAB" },
	{ name: "Triamcinolone acetonide", id: "TRIAMCINOLONE_ACETONIDE" },
	{ name: "Methotrexate", id: "METHOTREXATE" },
	{ name: "Faricimab-svoa (Vabysmo)", id: "FARICIMAB_SVOA" },
]);

app.constant("INTRAVITREAL_IMPLANT_MEDICATION", [
	{ name: "Dexamethasone implant (Ozurdex)", id: "DEXAMETHASONE_IMPLANT" },
]);

app.constant("SUBTENONS_INJECTION_MEDICATION", [
	{ name: "Triamcinolone acetonide", id: "TRIAMCINOLONE_ACETONIDE" },
]);

app.constant("ORBITAL_FLOOR_MEDICATION", [
	{ name: "Triamcinolone acetonide", id: "TRIAMCINOLONE_ACETONIDE" },
]);

app.constant("SUBCONJUNCTIVAL_INJECTION_MEDICATION", [
	{ name: "Dexamethasone", id: "DEXAMETHASONE" },
	{ name: "Bevacizumab (Avastin)", id: "BEVACIZUMAB" },
]);

app.constant("INTRAVITREAL_TAP_INJECT_MEDICATION", [
	{ name: "Vitreous Tap", id: "VITREOUS_TAP" },
	{ name: "Cefazolin", id: "CEFAZOLIN" },
	{ name: "Vancomycin", id: "VANCOMYCIN" },
	{ name: "Amikacin", id: "AMIKACIN" },
	{ name: "Ceftazidime", id: "CEFTAZIDIME" },
]);

app.constant("AUTHENTICATION_STATE", {
	normal: 0,
	waitingMFACode: 1,
	configMFA: 2,
	updateTempPassword: 3
});

app.constant("CORRECTION_TYPE", [
	{ name: "Spectacles", value: "SPECTACLES" },
	{ name: "Soft contact lens", value: "SOFT_CONTACT_LENS" },
	{ name: "Rgp contact lens", value: "RGP_CONTACT_LENS" },
	{ name: "Scleral contact lens", value: "SCLERAL_CONTACT_LENS" },
]);

app.constant("REFERRAL_REASON", [
	{ name: "Cataract", value: "CATARACT" },
	{ name: "Cornea", value: "CORNEA" },
	{ name: "Glaucoma", value: "GLAUCOMA" },
	{ name: "Retina", value: "RETINA" },
	{ name: "Uveitis", value: "UVEITIS" },
	{ name: "Neuro Ophthalmology", value: "NEURO_OPHTHALMOLOGY" },
	{ name: "Paediatrics and Strabismus", value: "PAEDIATRICS_AND_STRABISMUS" },
	{ name: "Oculoplastics (Eyelid and Orbit)", value: "OCULOPLASTICS_EYELID_AND_ORBIT" },
	{ name: "Other", value: "OTHER" },
]);

app.constant("REFERRAL_PRIORITY", [
	{ name: "Ideally seen within next 5 days (urgent priority - please follow up this referral with a phone call to notify the doctor or hospital)", value: "URGENT" },
	{ name: "See as soon as possible (high priority)", value: "HIGH" },
	{ name: "See when available (routine priority)", value: "ROUTINE" },
]);

app.constant("FILE_TYPES", {
	image: [
		{ id: "BIOMETRY", name: "Biometry" },
		{ id: "OCT_MACULA", name: "OCT macula" },
		{ id: "OCT_GLAUCOMA", name: "OCT glaucoma" },
		{ id: "OCT_ANTERIOR_SEGMENT", name: "OCT anterior segment" },
		{ id: "VISUAL_FIELD", name: "Visual field" },
		{ id: "CORNEAL_TOMOGRAPHY", name: "Corneal tomography" },
		{ id: "IMAGE", name: "Image" },
		{ id: "REFERRAL", name: "Referral" },
		{ id: "CLINIC_LETTER", name: "Clinic letter" },
		{ id: "AGREEMENT_TO_TREATMENT", name: "Agreement to treatment" },
		{ id: "CATQUEST", name: "Catquest" },
		{ id: "SCANNED_HISTORIC_NOTES", name: "Scanned historic notes" },
		{ id: "SCANNED_OPERATION_NOTE", name: "Scanned operation note" },
		{ id: "DISCHARGE_SUMMARY", name: "Discharge summary" },
		{ id: "HISTOLOGY", name: "Histology" },
		{ id: "LAB_RESULTS ", name: "Lab results " },
		{ id: "OTHER", name: "Other" },
	],
	word: [
		{ id: "CLINICAL_NOTES", name: "Clinical notes" },
		{ id: "CLINIC_LETTER", name: "Clinic letter" },
		{ id: "REFERRAL", name: "Referral" },
		{ id: "AGREEMENT_TO_TREATMENT", name: "Agreement to treatment" },
		{ id: "CATQUEST", name: "Catquest" },
		{ id: "SCANNED_HISTORIC_NOTES", name: "Scanned historic notes" },
		{ id: "SCANNED_OPERATION_NOTE", name: "Scanned operation note" },
		{ id: "DISCHARGE_SUMMARY", name: "Discharge summary" },
		{ id: "OTHER", name: "Other" },
	],
	video: [{ id: "VIDEO", name: "Video" }],
});

app.constant("CALENDAR_SEEN",[
	{ name: "Arrived", value: "arrived" },
	{ name: "Technician start", value: "technician_started" },
	{ name: "Technician finish", value: "technician_finished" },
	{ name: "Nurse start", value: "nurse_started" },
	{ name: "Nurse finish", value: "nurse_finished" },
	{ name: "Optometrist start", value: "optometrist_started" },
	{ name: "Optometrist finish", value: "optometrist_finished" },
	{ name: "Orthoptist start", value: "orthoptist_started" },
	{ name: "Orthoptist finish", value: "orthoptist_finished" },
	{ name: "Doctor start", value: "doctor_started" },
	{ name: "Doctor finish", value: "doctor_finished" },
	{ name: "Departed", value: "departed" },
]);

app.constant("CALENDAR_CNA_DNA",[
	{ name: "Could not attend", value: "could_not_attend" },
	{ name: "Did not attend", value: "did_not_attend" },
	{ name: "Rebooked", value: "rebooked"},
]);


app.constant("OTHER_REFERRAL_DHB_LIST",[
	{value: "AUCKLAND", name: "HNZ Auckland"},
	{value: "BAY_OF_PLENTY", name: "HNZ Bay of Plenty"},
	{value: "CANTERBURY", name: "HNZ Canterbury"},
	{value: "CAPITAL_AND_COST", name: "HNZ Capital and Coast"},
	{value: "COUNTIES_MANUKAU", name: "HNZ Counties Manukau"},
	{value: "HAWKES_BAY", name: "HNZ Hawke's Bay"},
	{value: "HUTT_VALLEY", name: "HNZ Hutt Valley"},
	{value: "LAKES", name: "HNZ Lakes"},
	{value: "MID_CENTRAL", name: "HNZ MidCentral"},
	{value: "NELSON_MARLBOROUGH", name: "HNZ Nelson Marlborough"},
	{value: "NORTHLAND", name: "HNZ Northland"},
	{value: "SOUTH_CANTERBURY", name: "HNZ South Canterbury"},
	{value: "SOUTHERN", name: "HNZ Southern"},
	{value: "TAIRAWHITI", name: "HNZ Tairawhiti"},
	{value: "TARANAKI", name: "HNZ Taranaki"},
	{value: "WAIKATO", name: "HNZ Waikato"},
	{value: "WAIRARAPA", name: "HNZ Wairarapa"},
	{value: "WAITEMATA", name: "HNZ Waitematad"},
	{value: "WEST_COST", name: "HNZ West Coast"},
	{value: "WHANGANUI", name: "HNZ Whanganui"},
])

app.constant("REFERENCE_TYPE",[
	{value: "CASH", name: "Cash"},
	{value: "EFTPOS", name: "EFTPOS"},
	{value: "CREDIT_CARD", name: "Credit card"},
	{value: "INSURANCE", name: "Insurance"},
	{value: "INTERNET_BANKING", name: "Internet banking"},
	{value: "OTHER", name: "Other"},
])

app.constant("APPOINTMENT_TYPE",[
	{value: "CLINIC_APPOINTMENT", name: "Clinic Appointment"},
	{value: "CLINIC_PROCEDURE", name: "Clinic Procedure"},
	{value: "THEATRE_PROCEDURE", name: "Theatre Procedure"},
	{value: "UNKNOWN", name: "Unknown"},
])

app.constant('NHI_VERSION_V2',true);

app.constant("ANAESTHETIC_TYPE",[
	{value: "TOPICAL_SUBTENONS", name: "Topical Subtenons"},
	{value: "LOCAL", name: "Local"},
	{value: "LOCAL_SEDATION", name: "Local Sedation"},
	{value: "GENERAL", name: "General"},
]);

app.constant("SURGERY_AGREEMENT_TYPE",[
	{value: "CATARACT_SURGERY", name: "Cataract Surgery"},
	{value: "PPCEB", name: "PPCEB"},
	{value: "PENETRATING_KERATOPLASTY", name: "Penetrating Keratoplasty"},
	{value: "LAMELLAR_KERATOPLASTY", name: "Lamellar Keratoplasty"},
	{value: "DMEK_KERATOPLASTY", name: "Membrane Keratoplasty"},
	{value: "DSAEK_KERATOPLASTY", name: "Stripping Keratoplasty"},
	{value: "INTRACORNEAL_RING_SEGMENTS", name: "Intracorneal Ring Segments"},
	{value: "SUPERFICIAL_KERATECTOMY", name: "Superficial Keratectomy"},
	{value: "CORNEAL_CROSSLINKING", name: "Corneal Crosslinking"},
	{value: "TRABECULECTOMY", name: "Trabeculectomy"},
	{value: "INSERTION_GLAUCOMA_TUBE", name: "Insertion Glaucoma Tube"},
	{value: "INSERTION_MIGS", name: "Insertion Migs"},
	{value: "SCLERAL_FIXATED_LENS", name: "Scleral Fixated Lens"},
	{value: "IRIS_IMPLANT", name: "Iris Implant"},
	{value: "PIGGYBACK_LENS", name: "Piggyback Lens"},
	{value: "PHAKIC_LENS", name: "Phakic Lens"},
	{value: "INTRAVITREAL_INJECTION", name: "Intravitreal Injection"},
	{value: "VITRECTOMY", name: "Vitrectomy"},
	{value: "EPIRETINAL_MEMBRANE_PEEL", name: "Epiretinal Membrane Peel"},
	{value: "RETINAL_DETACHMENT_REPAIR", name: "Retinal Detachment Repair"},
	{value: "DELAMINATION", name: "Delamination"},
	{value: "YAG_LASER", name: "YAG Laser"},
	{value: "SLT", name: "SLT"},
	{value: "ALT", name: "ALT"},
	{value: "LASER_COAGULATION_RETINA", name: "Laser Coagulation Retina"},
	{value: "CYCLOABLATION", name: "Cycloablation"},
	{value: "BARRIER_LASER_RETINOPEXY", name: "Barrier Laser Retinopexy"},
	{value: "IRIDOTOMY", name: "Iridotomy"},
	{value: "LATERAL_TARSAL_STRIP", name: "Lateral Tarsal Strip"},
	{value: "ENUCLEATION", name: "Enucleation"},
	{value: "EVISCERATION", name: "Evisceration"},
	{value: "WEDGE_EXCISION_BIOPSY", name: "Wedge Excision Biopsy"},
	{value: "BLEPHAROPLASTY", name: "Blepharoplasty"},
	{value: "ENTROPION_REPAIR", name: "Entropion Repair"},
	{value: "INSERTION_DACRYOCYSTORHINOSTOMY_TUBE", name: "Insertion Dacryocystorhinostomy Tube"},
	{value: "DACRYOCYSTORHINOSTOMY", name: "Dacryocystorhinostomy"},
	{value: "CANTHOTOMY_CANTHOLYSIS", name: "Canthotomy Cantholysis"},
	{value: "REPAIR_CORNEAL_LACERATION", name: "Repair Corneal Laceration"},
	{value: "REPAIR_RUPTURED_GLOBE", name: "Repair Ruptured Globe"},
	{value: "REPAIR_LID_LACERATION", name: "Repair Lid Laceration"},
	{value: "REPAIR_LID_LACERATION_CANALICULAR_STENT", name: "Repair Lid Laceration Canalicular Stent"},
	{value: "REPAIR_ORBITAL_FRACTURE", name: "Repair Orbital Fracture"},
]);

app.constant('FEATURE_AGREEMENT_SURGERY',true);
app.constant('DYMO_PRINT_LABEL_947',true);
app.constant('CALENDAR_AVAILABILITY_991',false);
app.constant('ETHNICITY_CODING_272',true);
app.constant('NZF_INTEGRATION_1038',true);
app.constant('OPNOTE_SAVE_ALL_SETTINGS_1020',true);
app.constant('VIRTUAL_PROGRESS_NOTE_1027',true);
app.constant('TASK_TABLE_PAGINATION_1044',true);
app.constant('OPNOTE_PATIENT_COMMENT',true);


	







